import React from "react";
import "./App.css";
import Loader from "./components/Loader";

function App() {
  // React.useEffect(() => {
  //   const redirectToDownloadPage = setTimeout(() => {
  //     // Enter One Link here
  //     const link = "";
  //     window.location.href = link;

  //     return () => {
  //       clearTimeout(redirectToDownloadPage);
  //     };
  //   }, 3000);
  // }, []);

  return <Loader />;
}

export default App;
